<template>
  <v-card
    class="table-sidebar rounded-lg mb-2"
    elevation="7">
    <CyButton
      class="close-btn mt-2"
      theme="primary"
      variant="tertiary"
      icon="close"
      icon-only
      @click="$emit('close')"/>
    <v-card-title
      :class="['header', tableData.canonical ? 'pl-8' : 'pl-4']">
      <CyTooltip
        max-width="230px"
        bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <div
              v-show="tableData.canonical"
              class="header__title"
              data-cy="header-title-primary">
              <span
                class="header__title marker"
                data-cy="marker"
                :style="{ 'background-color': tableData.canonicalColor || 'transparent' }"/>
              <CyTag
                class="header__title text"
                :label="displayLabel(mainGrouping)"
                variant="default">
                {{ tableData.canonical }}
              </CyTag>
            </div>
          </div>
        </template>
        <span v-text="tableData.canonical"/>
      </CyTooltip>
      <CyTag
        v-show="!_.isNil(secondaryGrouping) && !_.isNil(tableData.childCanonical)"
        class="header__and"
        data-cy="title-and"
        variant="warning">
        {{ _.toUpper($t('and')) }}
      </CyTag>
      <CyTooltip
        max-width="230px"
        bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <div
              v-show="!_.isNil(secondaryGrouping) && !_.isNil(tableData.childCanonical)"
              class="header__title"
              data-cy="header-title-secondary">
              <CyTag
                class="header__title text"
                :label="displayLabel(secondaryGrouping)"
                variant="default">
                {{ tableData.childCanonical }}
              </CyTag>
            </div>
          </div>
        </template>
        <span v-text="tableData.childCanonical"/>
      </CyTooltip>
    </v-card-title>
    <v-card-subtitle
      :class="['header', tableData.canonical ? 'pl-8' : 'pl-4']">
      <CyTag
        class="header__title text"
        data-cy="subtitle-period"
        variant="default">
        {{ formatPeriod }}
      </CyTag>
      <div
        v-show="false"
        data-cy="subtitle-trend"
        :class="['header__trend', tableData.canonical ? 'pt-1' : 'pt-4']"
        v-text="$t('trendOver', { date: trendPeriod })"/>
    </v-card-subtitle>

    <v-divider/>

    <v-card-text class="cy-scrollbars--always-show pt-6">
      <div
        v-for="dataType in ['cost', 'kwh', 'co2e']"
        :key="dataType"
        class="content-item">
        <div
          class="item__title"
          v-text="$t(`titles.${dataType}`)"/>
        <div class="item__value">
          <span
            class="item__value number"
            data-cy="value-number"
            v-text="formatAmount(tableData.values[dataType])"/>
          <span
            class="item__value unit"
            data-cy="value-unit"
            v-html="$sanitizeHtml(getUnitSymbol(dataType))"/>
        </div>
        <div
          v-if="!_.isEmpty(tableData.trendValues)"
          class="item__trend">
          <CyCloudCostManagementTrendChip
            class="item__trend chip"
            data-cy="trend-chip"
            :value="getTrendValuesByType(dataType)"/>
          <span
            v-if="false"
            class="item__trend value"
            data-cy="trend-value"
            v-html="$sanitizeHtml(getAbsoluteChangeByType(dataType))"/>
        </div>
      </div>

      <v-divider/>

      <div class="content-emission-equivalence py-6">
        <div
          class="emission-equivalence__title"
          v-text="$t('titles.equivalence')"/>

        <CyCloudCostManagementEmissionEquivalence
          :value="tableData.values.co2e"
          simplified-text
          icon-size="73"/>
      </div>

      <v-divider/>

      <i18n
        path="docText"
        tag="p"
        class="content-link pt-6">
        <template #learn>
          <a
            :href="$docLinks.cloudCostManagement.cloudCarbonFootprint"
            target="_blank"
            rel="noopener noreferrer"
            class="cy-link text-decoration-underline"
            v-text="$t('learn')"/>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CyCloudCostManagementEmissionEquivalence from '@/components/cloud-cost-management/emission-equivalence.vue'
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip.vue'
import { getCurrencySymbol, formatAmount } from '@/utils/helpers'
import { groupingOptions } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCloudCostManagementProviderDetailTableSidebar',
  components: {
    CyCloudCostManagementTrendChip,
    CyCloudCostManagementEmissionEquivalence,
  },
  props: {
    tableData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    $static: () => ({
      groupingOptions,
    }),
    ...mapState('organization/cloudCostManagement', {
      currency: (state) => state.queryBody.currency,
      mainGrouping: (state) => state.queryBody.group_by[0],
      secondaryGrouping: (state) => state.queryBody.group_by[1],
      startDate: (state) => state.queryBody.begin,
      endDate: (state) => state.queryBody.end,
    }),
    formatPeriod () {
      const { tableData: { period } } = this
      if (!period) return
      if (_.isArray(period)) {
        const earliestDate = $date.format(new Date(this.startDate), 'MMM dd, yyyy')
        const latestDate = $date.format(new Date(this.endDate), 'MMM dd, yyyy')
        return earliestDate === latestDate ? earliestDate : `${earliestDate} - ${latestDate}`
      }
      return $date.format(period, 'MMM dd, yyyy')
    },
    trendPeriod () {
      const start = new Date(this.startDate)
      const end = new Date(this.endDate)

      const interval = $date.intervalToDuration({ start, end })
      const trendStartDate = $date.format($date.sub(start, interval), 'MMM dd, yyyy')
      const trendEndDate = $date.format($date.subDays(start, 1), 'MMM dd, yyyy')

      return `${trendStartDate} - ${trendEndDate}`
    },
  },
  methods: {
    getCurrencySymbol,
    formatAmount,
    displayLabel (value) {
      const group = _.find(this.$static.groupingOptions, ['value', value])
      return _.get(group, 'label', this.$t('cloudCostManagement.groupBy.noGrouping'))
    },
    getTrendValuesByType (dataType) {
      const { tableData: { trendValues } } = this
      return [_.get(_.head(trendValues), dataType, 0), _.get(_.last(trendValues), dataType, 0)]
    },
    getAbsoluteChangeByType (dataType) {
      const { tableData: { trendValues }, currency } = this
      const difference = _.get(_.last(trendValues), dataType, 0) - _.get(_.head(trendValues), dataType, 0)
      if (dataType === 'cost') {
        return this.formatAmount(difference, { currency, signDisplay: 'always' }).replace(/^(.)/, '$1 ')
      }
      return `${this.formatAmount(difference).replace(/^(.)/, '$1 ')} ${this.$t(`untranslated.${dataType}`)}`
    },
    getUnitSymbol (dataType) {
      if (dataType === 'cost') return this.getCurrencySymbol(this.currency)
      return this.$t(`untranslated.${dataType}`)
    },
  },
  i18n: {
    messages: {
      en: {
        docText: '{learn} how emissions and energy are calculated.',
        learn: 'Learn',
        trendOver: 'Trend over {date}',
        titles: {
          cost: '@:cloudCostManagement.cost',
          kwh: '@:cloudCostManagement.energyConsumption',
          co2e: '@:cloudCostManagement.carbonEmissions',
          equivalence: 'Total cloud carbon emissions per selected date range are equal to taking',
        },
      },
      es: {
        docText: '{learn} cómo se calculan las emisiones y la energía.',
        learn: 'Descubra',
        trendOver: 'Tendencia durante {date}',
        titles: {
          cost: '@:cloudCostManagement.cost',
          kwh: '@:cloudCostManagement.energyConsumption',
          co2e: '@:cloudCostManagement.carbonEmissions',
          equivalence: 'Las emisiones totales de carbono de la nube en el rango de fechas seleccionado son iguales a tomar',
        },
      },
      fr: {
        docText: `{learn} comment les émissions et l'énergie sont calculées.`,
        learn: 'Découvrez',
        trendOver: 'Tendance sur {date}',
        titles: {
          cost: '@:cloudCostManagement.cost',
          kwh: '@:cloudCostManagement.energyConsumption',
          co2e: '@:cloudCostManagement.carbonEmissions',
          equivalence: 'Les émissions totales de carbone du cloud pour la plage de dates sélectionnée correspondent à',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.table-sidebar {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 336px;
  height: calc(100% - #{$offset} * 2);
  overflow: hidden;

  .close-btn {
    position: absolute;
    top: 0;
    right: 6px;
  }

  .header {
    max-width: 280px;

    &__and {
      display: inline-flex;
      align-self: baseline;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    &__title {
      display: flex;
      align-items: center;
      max-width: 250px;
      margin-bottom: 5px;

      &.marker {
        position: absolute;
        left: 16px;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        border-radius: 50%;
        vertical-align: middle;
      }

      &.text {
        display: inline-block;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__trend {
      padding-top: 5px;
      color: cy-get-color("grey", "dark-1");
    }
  }

  .v-card__text {
    height: 100%;
    overflow-y: auto;
  }

  .content {
    &-item {
      margin-bottom: 30px;
    }

    &-link {
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-sm;
    }
  }

  ::v-deep .emission-equivalence {
    display: flex;
    padding-top: 24px;

    &__title {
      color: cy-get-color("primary", "light-2");
    }

    .text-flights {
      font-family: $font-family-condensed;
      font-size: 30px;
      font-weight: $font-weight-default;
      line-height: 45px;
    }

    .text-from {
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  .item {
    &__title {
      padding-bottom: 12px;
      color: cy-get-color("primary", "light-2");
      font-size: $font-size-xl;
      font-weight: $font-weight-bolder;
    }

    &__value {
      font-family: $font-family-condensed;

      &.number {
        color: cy-get-color("primary");
        font-size: $font-size-h2;
        font-weight: $font-weight-bolder;
        line-height: 100%;
      }

      &.unit {
        color: cy-get-color("primary", "light-2");
        font-size: 18px;
      }
    }

    &__trend {
      display: flex;
      align-items: center;
      margin-top: 5px;

      &.chip {
        font-size: $font-size-sm;
      }

      &.value {
        margin-left: 17px;
        color: cy-get-color("primary", "light-2");
        font-size: $font-size-sm;
      }
    }
  }
}
</style>
